<template>
    <div class="section" key="finance">
        <div class="section-title">
            <div class="author"><img src="@/assets/images/rabier.png"></div>
            <h3><span>Schritt 1 Finanzierung</span> Wie möchten Sie die Kosten der Scheidung begleichen?</h3>
            <p>Bitte wählen Sie aus den folgenden Optionen</p>
        </div>
        <div class="form-container">
            <div class="form-card large-card">
            <input type="radio" v-model="user.zahlung" name="kosten" value="selber" id="radio-2-1">
            <label for="radio-2-1" v-on:click="incrementChecked()"><span class="label-icon icon-circle"></span><span>Selber begleichen <br> <b>In einer Zahlung selbst begleichen</b></span></label>
            </div>
            <div class="form-card large-card">
            <input type="radio" v-model="user.zahlung" name="kosten" value="raten" id="radio-2-2">
            <label for="radio-2-2" v-on:click="incrementChecked()"><span class="label-icon icon-circle"></span><span>Ratenfinanzierung (ohne Mehrkosten) <br> <b>Finanzieren Sie die Anwaltskosten ohne Mehrkosten auf bis zu 6 Monate. Sie zahlen zinsfrei 3 Raten im 2-Monatsabstand.</b></span></label>
            </div>
            <div class="form-card large-card">
            <input type="radio" v-model="user.zahlung" name="kosten" value="verfahren" id="radio-2-3">
            <label for="radio-2-3" v-on:click="incrementChecked()"><span class="label-icon icon-circle"></span><span>Verfahrenskostenhilfe beantragen <br> <b>Verfügen Sie nur über ein geringes Einkommen? In diesem Fall kommt für Sie die Beantragung von Verfahrenskostenhilfe in Betracht. Ihr Berater informiert Sie gerne.</b></span></label>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'Finance',
  computed: mapState({
    user: (state) => state.user,
    checked: (state) => state.checked
  }),
  methods: {
    ...mapMutations([
      'incrementChecked'
    ]),
  }
}
</script>